
















import { Component, Vue, Watch } from "vue-property-decorator";

import { getAndStoreCode } from "../code";

@Component({})
export default class Code extends Vue {
  loading = true;
  error = "";

  created(): void {
    this.getCode();
  }

  @Watch("$route")
  onRouteChange(): void {
    this.getCode();
  }

  async getCode(): Promise<void> {
    const accessToken = this.$auth.getAccessToken();
    const given_name = this.authState.idToken?.claims?.given_name || "";
    if (typeof accessToken === "string") {
      return getAndStoreCode(accessToken, given_name).then(
        () => {
          setTimeout(() => {
            this.$router.push({ name: "home" });
          }, 500);
        },
        (e) => {
          this.loading = false;
          this.error = e;
        }
      );
    } else {
      const redirectToAuth = this.$auth.signInWithRedirect();
      const timeout: Promise<void> = new Promise((_resolve, reject) => {
        const id = setTimeout(() => {
          clearTimeout(id);
          reject("Sign-in redirection timed out, please retry.");
        }, 5000);
      });
      return Promise.race([redirectToAuth, timeout]).catch((e) => {
        this.loading = false;
        this.error = e;
        this.$auth.signOut();
      });
    }
  }
  retry(): void {
    window.location.reload();
  }
}
