import { DateTime } from "luxon";
import Axios from "axios";

export interface Door {
  door_id: string;
  place_id: string;
  name: string;
  label: string;
}

export interface Code {
  code: string;
  totp_secret: string;
  valid_until: DateTime;
  doors: Door[];
  creator_id: string;
  given_name: string;
}

export interface CodeId {
  doors: Door[];
  creator_id: string;
  given_name: string;
}

const codeKey = "code";

export async function getAndStoreCode(
  accessToken: string,
  given_name: string
): Promise<Code> {
  return Axios.post(`${process.env.VUE_APP_BASE_URL}/api/code/member`, null, {
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then(
    (res) => {
      const code: Code = {
        code: res.data.code_id,
        totp_secret: res.data.totp_secret,
        valid_until: DateTime.fromISO(res.data.ends_at),
        doors: res.data.doors,
        creator_id: res.data.creator_id,
        given_name,
      };

      window.localStorage.setItem(codeKey, JSON.stringify(code));

      return code;
    },
    (err) => {
      const msg =
        err.response.data !== ""
          ? `${err.message} → ${err.response.data}`
          : err.message;
      throw msg;
    }
  );
}

function parseCode(json: string): Code {
  const code = JSON.parse(json);
  code.valid_until = DateTime.fromISO(code.valid_until);
  return code;
}

export function hasValidCode(): boolean {
  const data = window.localStorage.getItem(codeKey);
  if (data === null) {
    return false;
  } else {
    const code = parseCode(data);
    if (code.valid_until.diffNow().as("seconds") > 0) {
      return true;
    } else {
      forgetCode();
      return false;
    }
  }
}

export function currentCodeId(): CodeId | null {
  const data = window.localStorage.getItem(codeKey);
  if (data === null) {
    return null;
  } else {
    const code = parseCode(data);
    if (code.valid_until.diffNow().as("seconds") > 0) {
      return {
        doors: code.doors,
        creator_id: code.creator_id,
        given_name: code.given_name,
      };
    } else {
      forgetCode();
      return null;
    }
  }
}

export function forgetCode(): void {
  window.localStorage.removeItem(codeKey);
}
