
















import { Component, Vue, Watch } from "vue-property-decorator";
import { currentCodeId } from "../code";
import { fetchAssociatedOffice } from "../utils";

@Component
export default class Home extends Vue {
  user: unknown = null;
  hasValidCode = false;
  givenName: string | undefined = undefined;
  token: string | undefined = "";
  authState: any = { isAuthenticated: false };
  loading = true;

  @Watch("authState.isAuthenticated")
  async onAuthStateChanged(isAuthenticated: boolean) {
    if (isAuthenticated) {
      try {
        await this.checkAuthenticationAndRetrieveName();
        const placeId = await fetchAssociatedOffice(this.token!);
        if (placeId) {
          window.location.href = `/place/${placeId}`;
        }
      } catch (error) {
        console.error("Error during authState watch:", error);
      }
    } else {
      console.warn(
        "User is not authenticated, cannot proceed with authentication or fetching data."
      );
    }
  }

  async checkAuthenticationAndRetrieveName() {
    if (this.token) {
      this.hasValidCode = true;
      if (this.hasValidCode) {
        const codeId = currentCodeId();
        if (codeId) {
          this.givenName = codeId.given_name;
        }
      } else {
        this.givenName = this.authState.idToken?.claims?.given_name;
      }
    } else {
      window.location.href = `/code`;
    }
  }

  async getAccessToken(): Promise<string | undefined> {
    try {
      const accessToken = await this.$auth.getAccessToken();
      if (accessToken) {
        this.token = accessToken;
        console.log("Access token retrieved:", this.token);
        return accessToken;
      }
    } catch (error) {
      console.error("Error getting access token:", error);
      return undefined;
    }
  }

  sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async mounted() {
    try {
      const token = await this.getAccessToken();
      if (token) {
        this.token = token;
        this.authState.isAuthenticated = true;
      }
    } catch (error) {
      console.error("Error during the mounted lifecycle:", error);
    }
    setTimeout(() => {
      this.loading = false;
    }, 1500);
  }
}
