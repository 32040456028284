import Vue from "vue";
import { OktaAuth } from "@okta/okta-auth-js";
import OktaVue from "@okta/okta-vue";
import Axios, { Method } from "axios";
import * as Sentry from "@sentry/vue";

import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import { registerServiceWorker } from "./registerServiceWorker";

import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

registerServiceWorker(false);
Vue.config.productionTip = false;

if (
  typeof process.env.VUE_APP_SENTRY_DSN === "string" &&
  process.env.VUE_APP_SENTRY_DSN != ""
) {
  const env =
    typeof process.env.VUE_APP_SENTRY_ENVIRONMENT === "string"
      ? { environment: process.env.VUE_APP_SENTRY_ENVIRONMENT }
      : {};

  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    release: process.env.VUE_APP_COMMIT_ID,
    ...env,
    Vue,
    attachProps: true,
    logErrors: true,
  });
}

const realmUrl =
  `${process.env.VUE_APP_KEYCLOAK_URL}/realms/${process.env.VUE_APP_KEYCLOAK_REALM}/`.replace(
    /[/]*$/,
    ""
  );
const clientId = process.env.VUE_APP_KEYCLOAK_CLIENT_ID;

const oktaAuth = new OktaAuth({
  issuer: realmUrl,
  authorizeUrl: `${realmUrl}/protocol/openid-connect/auth`,
  userinfoUrl: `${realmUrl}/protocol/openid-connect/userinfo`,
  tokenUrl: `${realmUrl}/protocol/openid-connect/token`,
  logoutUrl: `${realmUrl}/protocol/openid-connect/logout`,
  revokeUrl: `${realmUrl}/protocol/openid-connect/revoke`,
  clientId,
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: ["openid", "profile", "email"],
  httpRequestClient: (method, url, args) => {
    // Intercept logout call because it seems broken with okta-auth-js + keycloak
    if (url.endsWith("/revoke")) {
      window.location.href = `${realmUrl}/protocol/openid-connect/logout?redirect_uri=${encodeURIComponent(
        window.location.origin
      )}`;
      return Promise.resolve({ responseText: "", status: 200 });
    }

    const headers = args.headers as Record<string, string> | undefined;
    if (typeof headers !== "undefined") {
      if (typeof headers["X-Okta-User-Agent-Extended"] !== "undefined") {
        delete headers["X-Okta-User-Agent-Extended"];
      }
    }

    return Axios.request({
      method: method as Method,
      url,
      headers,
      data: args.data,
      withCredentials: args.withCredentials,
    }).then((res) => {
      return { responseText: res.data, status: res.status };
    });
  },
});

Vue.use(OktaVue, { oktaAuth });
new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
