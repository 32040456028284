export async function fetchAssociatedOffice(
  token: string
): Promise<string | null> {
  try {
    const response = await fetch(
      `${process.env.VUE_APP_BASE_URL}/api/get_associated_office_from_jwt`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }
    const data = await response.json();
    if (data && data.place_id) {
      return data.place_id;
    } else {
      console.error("Invalid response data", data);

      return null;
    }
  } catch (error) {
    console.error("Error fetching associated office:", error);
    return null;
  }
}
