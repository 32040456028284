import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { LoginCallback } from "@okta/okta-vue";

import Home from "../views/Home.vue";
import Code from "../views/Code.vue";
import PlaceDetails from "../views/PlaceDetails.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { path: "/login/callback", component: LoginCallback },
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/code",
    name: "code",
    component: Code,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/place/:placeId",
    name: "PlaceDetails",
    component: PlaceDetails,
    props: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
